import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { HttpClient } from '@angular/common/http';
import { ExpensesFilters } from '../models/expense/expense-filters';
import { Expense } from '../models/expense/expense';
import { Observable, of } from 'rxjs';
import { ExpenseReason } from '../models/expense/expense-reason';

@Injectable({
  providedIn: 'root'
})
export class ExpensesService extends BaseService {

  constructor(private http: HttpClient) {
    super(http);
  }

  findExpenses(filters: ExpensesFilters) {
    return super.put<Expense[]>(
      `Expenses/Find`, filters);
  }

  createExpense(expense: FormData) {
    return super.post(
      `Expenses`, expense);
  }

  findReasons(): Observable<ExpenseReason[]> {
    return super.get<ExpenseReason[]>('Expenses/ReasonList');
  }

  getById(expenseId): Observable<Expense> {
    return super.get<Expense>(`Expenses/${expenseId}`);
  }

  deleteExpense(expenseId) {
    return super.deleteMethod(`Expenses/${expenseId}`);
  }

  downloadFile(id: string, fileId: string) : Observable<Blob> {
    return super.getFile(`ExpenseFile/download/${id}/${fileId}`);
  }
}

